import React from 'react';
import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';

export const AppraisalFindVinDrawerBackButton = ({ toggleDrawer }) => (
  <Button
    color="link"
    size="lg"
    className="close-btn p-0 d-flex align-items-center"
    aria-label="Close"
    onClick={toggleDrawer}
  >
    <i className="icon-arrow-left3 size-16" aria-hidden />
    <span className="size-14 ml-0_5 text-capitalize font-weight-normal">Back</span>
  </Button>
);

AppraisalFindVinDrawerBackButton.propTypes = {
  toggleDrawer: PropTypes.func.isRequired,
};
