import { padEnd } from 'lodash';
import { AppraiseTabContentFormWrapper } from 'site-modules/shared/components/appraisal/appraisal-tabs/appraise-tab-content-form-wrapper';
import { AppraiseTabContentVdpFormWrapper } from 'site-modules/shared/components/appraisal/appraisal-tabs/appraise-tab-content-vdp-form-wrapper';
import { EpoTabContentFormWrapper } from 'site-modules/shared/components/appraisal/trade-in-appraiser-steps/license-plate-and-vin-step/epo-tab-content-form-wrapper';

const COLUMN_SIZE = {
  LICENSE_PLATE: {
    ADDRESS_TAB: {
      xs: 12,
    },
    VIN_BY_ADDRESS_OVERLAY: {
      xs: 12,
      md: 7,
    },
    APPRAISAL_VALUE: {
      xs: 12,
      md: 6,
    },
    DEFAULT: {
      xs: 12,
      md: 5,
      lg: 6,
      xl: 5,
    },
    VDP_EMBEDDED: {
      xs: 12,
      md: 6,
    },
    ESCAPE_HATCH: {
      xs: 7,
    },
    DRAWER_TYPE: {
      xs: 12,
      md: 6,
    },
  },
  STATE_CODE: {
    ADDRESS_TAB: {
      xs: 12,
    },
    VIN_BY_ADDRESS_OVERLAY: {
      xs: 12,
      md: 5,
    },
    APPRAISAL_VALUE: {
      xs: 12,
      md: 4,
      xl: 5,
    },
    DEFAULT: {
      xs: 12,
      md: 4,
      lg: 6,
      xl: 5,
    },
    VDP_EMBEDDED: {
      xs: 12,
      md: 6,
    },
    ESCAPE_HATCH: {
      xs: 5,
    },
    DRAWER_TYPE: {
      xs: 12,
      md: 6,
    },
  },
};

export function getLicensePlateTabStyleProperties({
  isFullWidth,
  isVinByAddressOverlay,
  isBackgroundStyleTabEnabled,
  isVdpEmbedded,
  isAppraisalValuePage,
  isEscapeHatchDrawer,
  isLocationStyleStep,
  isEscapeHatch,
  isProjectEveStyle,
  isLookUpStyleView,
  isSecondary,
  isMobile,
  isAddressTab,
}) {
  let FormWrapper;
  let licensePlateColSize;
  let stateCodeColSize;

  if (isAddressTab) {
    FormWrapper = AppraiseTabContentFormWrapper;
    licensePlateColSize = COLUMN_SIZE.LICENSE_PLATE.ADDRESS_TAB;
    stateCodeColSize = COLUMN_SIZE.STATE_CODE.ADDRESS_TAB;
  } else if (isVinByAddressOverlay || isBackgroundStyleTabEnabled || isProjectEveStyle) {
    FormWrapper = AppraiseTabContentFormWrapper;
    licensePlateColSize = COLUMN_SIZE.LICENSE_PLATE.VIN_BY_ADDRESS_OVERLAY;
    stateCodeColSize = COLUMN_SIZE.STATE_CODE.VIN_BY_ADDRESS_OVERLAY;
  } else if (isVdpEmbedded) {
    FormWrapper = AppraiseTabContentVdpFormWrapper;
    licensePlateColSize = COLUMN_SIZE.LICENSE_PLATE.VDP_EMBEDDED;
    stateCodeColSize = COLUMN_SIZE.STATE_CODE.VDP_EMBEDDED;
  } else if (isAppraisalValuePage && !isEscapeHatchDrawer && !isLocationStyleStep) {
    FormWrapper = EpoTabContentFormWrapper;
    licensePlateColSize = COLUMN_SIZE.LICENSE_PLATE.APPRAISAL_VALUE;
    stateCodeColSize = COLUMN_SIZE.STATE_CODE.APPRAISAL_VALUE;
    if (isEscapeHatch) {
      licensePlateColSize = COLUMN_SIZE.LICENSE_PLATE.ESCAPE_HATCH;
      stateCodeColSize = COLUMN_SIZE.STATE_CODE.ESCAPE_HATCH;
    }
  } else if (isLookUpStyleView || isEscapeHatchDrawer || isLocationStyleStep) {
    FormWrapper = AppraiseTabContentFormWrapper;
    licensePlateColSize = COLUMN_SIZE.LICENSE_PLATE.DRAWER_TYPE;
    stateCodeColSize = COLUMN_SIZE.STATE_CODE.DRAWER_TYPE;
  } else {
    FormWrapper = AppraiseTabContentFormWrapper;
    licensePlateColSize = COLUMN_SIZE.LICENSE_PLATE.DEFAULT;
    stateCodeColSize = COLUMN_SIZE.STATE_CODE.DEFAULT;
  }

  let btnWidthMd = isEscapeHatchDrawer ? 7 : 6;
  btnWidthMd = isLocationStyleStep ? 4 : btnWidthMd;

  const isFontSize16 = !isLocationStyleStep || isMobile || isVinByAddressOverlay;
  const isFontSize12 = ((!isAppraisalValuePage || isLocationStyleStep) && !isVinByAddressOverlay) || isAddressTab;

  return {
    FormWrapper,
    licensePlateColSize,
    stateCodeColSize,
    btnWidthMd,
    isFontSize16,
    isFontSize12,
    isVinByAddressOverlay,
    isBackgroundStyleTabEnabled,
    isVdpEmbedded,
    isAppraisalValuePage,
    isEscapeHatchDrawer,
    isLocationStyleStep,
    isEscapeHatch,
    isLookUpStyleView,
    isFullWidth,
    isSecondary,
    isMobile,
    isAddressTab,
  };
}

/**
 * Redacts license plate string value and replaces the trailing characters with 'X'
 * @param {String} licensePlate - License plate string value
 * @param {Number} numOfCharToExpose - how many characters to expose
 * @returns redacted license plate string with trailing 'X's
 */
export const redactLicensePlateString = (licensePlate, numOfCharToExpose = 3) => {
  const stringLength = licensePlate.length;
  if (stringLength < numOfCharToExpose) {
    return licensePlate;
  }

  const truncatedString = licensePlate.slice(0, numOfCharToExpose);

  return padEnd(truncatedString, stringLength, 'X');
};
