import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'site-modules/shared/components/spinner/spinner';

export const AppraisalLazyLoadModalLoadingPlaceholder = ({ toggleModal, creativeId }) => (
  <div data-tracking-parent={creativeId}>
    <div className="text-right">
      <button
        type="button"
        className="close-btn p-0 border-0 bg-transparent d-inline-block mt-1_25 mr-1_25"
        onClick={toggleModal}
      >
        <span className="icon-cross2 size-16 text-gray-darker" aria-hidden />
      </button>
    </div>
    <Spinner className="d-block mx-auto my-2" size={48} thickness={5} color="primary" />
  </div>
);

AppraisalLazyLoadModalLoadingPlaceholder.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  creativeId: PropTypes.string,
};

AppraisalLazyLoadModalLoadingPlaceholder.defaultProps = {
  creativeId: '',
};
