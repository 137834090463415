import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Row, Col } from 'reactstrap';

export class EpoTabContentFormWrapper extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    isEscapeHatch: PropTypes.bool,
    isEscapeHatchDrawer: PropTypes.bool,
    isLocationStyleStep: PropTypes.bool,
  };

  static defaultProps = {
    isEscapeHatch: false,
    isEscapeHatchDrawer: false,
    isLocationStyleStep: false,
  };

  render() {
    const { children, isEscapeHatch, isEscapeHatchDrawer, isLocationStyleStep } = this.props;
    const colSizeXl = isEscapeHatch || isEscapeHatchDrawer || isLocationStyleStep ? 12 : 8;

    return (
      <Row>
        <Col xs={12} xl={colSizeXl} className={classNames({ 'px-0': isLocationStyleStep })}>
          {children}
        </Col>
      </Row>
    );
  }
}
