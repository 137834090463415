export const TAB_ID = {
  LICENSE_PLATE: 'LICENSE_PLATE',
  VIN: 'VIN',
};

export const TAB_VIN_ERROR_ID = {
  SQUISH_VIN: 'SQUISH_VIN',
  VIN_ELIGIBILITY: 'VIN_ELIGIBILITY',
};

export const LP_AND_VIN_STEP_STORAGE_KEYS = {
  LICENSE_PLATE: 'LP_AND_VIN_STEP_LICENSE_PLATE',
  STATE_CODE: 'LP_AND_VIN_STEP_STATE_CODE',
  VIN: 'LP_AND_VIN_STEP_VIN',
};
