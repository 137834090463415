import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

export class AppraiseTabContentVdpFormWrapper extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    isFullWidth: PropTypes.bool,
  };

  static defaultProps = {
    isFullWidth: false,
  };

  render() {
    const { isFullWidth, children } = this.props;

    if (isFullWidth) {
      return <div>{children}</div>;
    }

    return (
      <Row>
        <Col xs={12} md={9}>
          {children}
        </Col>
      </Row>
    );
  }
}
