import React from 'react';
import PropTypes from 'prop-types';
import { lazyLoad } from 'site-modules/shared/components/lazy-load/lazy-load';
import { AppraisalLazyLoadModalLoadingPlaceholder } from 'site-modules/shared/components/appraisal/appraisal-lazy-load-modal-loading-placeholder/appraisal-lazy-load-modal-loading-placeholder';

const LoadingComponent = ({ creativeId, toggle }) => (
  <AppraisalLazyLoadModalLoadingPlaceholder creativeId={creativeId} toggleModal={toggle} />
);

LoadingComponent.propTypes = {
  creativeId: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
};

export const MultipleVinsModalContentAsync = lazyLoad({
  loader: () => import(/* webpackChunkName: "multiple-vins-modal-content" */ './multiple-vins-modal-content'),
  componentName: 'MultipleVinsModalContent',
  loading: LoadingComponent,
});
