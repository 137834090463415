import React, { Component, useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { noop, isEmpty } from 'lodash';

// Models
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import {
  PartnerOfferModel,
  buildLicensePlateStateCodeForVinPath,
  getLicensePlateErrorState,
} from 'client/data/models/partner-offer-model';
import {
  VehicleVinModel,
  buildStylesBasicPathFromSquishVin,
  getVinStylesDataWithMetadata,
  VehicleVinEntities,
} from 'client/data/models/vehicle-vin';

// Utils
import { validation } from 'site-modules/shared/components/form-validation/validation';
import { getSquishVIN } from 'site-modules/shared/utils/vin-utils';
import { EventToolbox } from 'client/utils/event-toolbox';
import { venomHistory } from 'client/utils/history/venom-history';
import {
  getLicensePlateTabStyleProperties,
  redactLicensePlateString,
} from 'site-modules/shared/components/appraisal/appraisal-tabs/license-plate-tab/license-plate-tab-utils';
import { delayHeavyTask } from 'client/site-modules/shared/utils/inp-utils';

// Context
import { AppraisalTabsContext } from 'site-modules/shared/components/appraisal/appraisal-tabs/appraisal-tabs-context';

// Constants
import { TrackingConstant } from 'client/tracking/constant';
import { APPRAISAL_VEHICLE_ENTRY_CREATIVE_ID } from 'site-modules/shared/constants/appraisal/appraisal';
import { LP_AND_VIN_STEP_STORAGE_KEYS } from 'site-modules/shared/components/appraisal/trade-in-appraiser-steps/license-plate-and-vin-step/constants';

// Components
import { AppraisalTabsSubmitButton } from 'site-modules/shared/components/appraisal/appraisal-tabs/appraisal-tabs-submit-button';
import { LicensePlateTab } from './license-plate-tab-content';
import { LicensePlateTabLpInput } from './license-plate-tab-lp-input';
import { LicensePlateTabStateCodeInput } from './license-plate-tab-state-code-input';

const ERROR_MESSAGE_MAP = {
  licensePlate: {
    INVALID: 'Please enter a valid license plate',
    LANDING_INVALID: vinTabLink => (
      <Fragment>
        We could not find that license plate. If your license plate is a special plate (vanity, disabled person, special
        interest, etc.), please use your {vinTabLink} instead.
      </Fragment>
    ),
    ERROR: "We're sorry, we could not look up your license plate at this time.",
    NO_STYLES:
      "We're sorry. We were unable to locate your license plate. Please try entering a VIN or a Make, Model, Year instead.",
  },
  stateCode: {
    INVALID: 'Please select a state',
  },
};

export const commonEventData = {
  action_name: TrackingConstant.ACTION_APPRAISE_VEHICLE,
  action_category: TrackingConstant.USER_ACTION_CATEGORY,
  action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
};

export class LicensePlateTabContainerUI extends Component {
  state = {
    licensePlateInputValue: this.props.licensePlate,
    stateCodeInputValue: this.props.stateCode,
    isLpValid: true,
    errorMap: new Map(),
    isSubmitting: false,
    wasFormSubmitted: false,
    isMultipleVinProcessed: false,
  };

  componentDidMount() {
    const { storage } = this.props;
    window.addEventListener('pageshow', this.setDefaultButtonStateForCachedPage);

    if (storage) {
      const storedLp = storage.get(LP_AND_VIN_STEP_STORAGE_KEYS.LICENSE_PLATE);
      const storageStateCode = storage.get(LP_AND_VIN_STEP_STORAGE_KEYS.STATE_CODE);

      let storageLp;
      try {
        storageLp = JSON.parse(storedLp); // Handles the case when an empty string was stored so we don't use the serialized JSON empty string
      } catch (e) {
        storageLp = storedLp;
      }

      if (storageStateCode) {
        this.onStateCodeChange({ stateCode: storageStateCode });
      }
      if (storageLp) {
        this.onLicensePlateChange({ target: { value: storageLp } });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      vinFromLicensePlate,
      licensePlate,
      stateCode,
      creativeId,
      licensePlateLookupError,
      setModelValue,
      vinTabLink,
      vinEligibility,
      isAppraisalValuePage,
      isLicensePlateAndVinStep,
      onCompleteCallback,
      vin: vinFromContext,
      isAppraiserPageOpened,
      isSellInCityState,
      isWIDG1125chal1,
    } = this.props;
    const {
      wasFormSubmitted,
      isSubmitting,
      errorMap,
      licensePlateInputValue,
      stateCodeInputValue,
      isMultipleVinProcessed,
    } = this.state;
    const vinValueFromLp = vinFromLicensePlate?.vin;
    const isMultiVins = vinFromLicensePlate?.vins?.length > 1;

    // Don't block the CTA for the multi-VINs case
    if (isMultiVins && isSubmitting) {
      this.resetIsSubmitting();
    }

    // Reset submitting state when /appraisal-value/ page is opened
    if (isAppraiserPageOpened && !prevProps.isAppraiserPageOpened) {
      this.resetIsSubmitting();
    }

    if (isMultiVins && isMultipleVinProcessed && !vinFromContext && !licensePlateLookupError) {
      // User hasn't selected a VIN from the multiple VINs, but license plate lookup was successful
      this.fireLicensePlateLookupTracking(TrackingConstant.VIN_FOUND);
      this.setIsMultipleVinProcessed(false);
      return;
    }

    if (
      wasFormSubmitted &&
      (isMultiVins ? isMultipleVinProcessed : isSubmitting) && // this enables checks for vin set in multi-vins modal after the form has been submitted
      vinFromLicensePlate &&
      licensePlateInputValue === licensePlate &&
      stateCodeInputValue === stateCode &&
      // wait for vinEligibility if /appraisal-value/ page and not license plate and VIN step
      (isAppraisalValuePage && vinValueFromLp ? !isEmpty(vinEligibility) || isLicensePlateAndVinStep : true)
    ) {
      // Happy path when VIN is found
      if (!isMultiVins && vinValueFromLp && !licensePlateLookupError) {
        this.fireLicensePlateLookupTracking(TrackingConstant.VIN_FOUND);

        // We don't need to set style IDs while on /appraisal-value/ page
        if (!isAppraisalValuePage) {
          this.setStylesIds(vinValueFromLp);
        }

        if (isWIDG1125chal1 && isSellInCityState) {
          venomHistory.push(`/sell-car/instant-cash-offer/?icoVin=${vinValueFromLp}`);
          return;
        }

        setModelValue('eligibilityCreativeIds', PartnerOfferModel, { [vinValueFromLp]: creativeId });
        onCompleteCallback(vinValueFromLp, vinEligibility);
        this.resetIsSubmitting();
        return;
      }

      if (isMultiVins && vinFromContext && !licensePlateLookupError) {
        // User has selected a VIN from multi-vin modal
        setModelValue('eligibilityCreativeIds', PartnerOfferModel, { [vinFromContext]: creativeId });
        onCompleteCallback(vinFromContext, vinEligibility);
        this.setIsMultipleVinProcessed(false);
        return;
      }

      const updatedErrorMap = new Map(errorMap);
      // Handles null/empty VIN.
      if (vinValueFromLp === null || licensePlateLookupError) {
        updatedErrorMap.set('licensePlate', ERROR_MESSAGE_MAP.licensePlate.ERROR);
        updatedErrorMap.delete('licensePlateWide');
      } else {
        updatedErrorMap.set('licensePlateWide', ERROR_MESSAGE_MAP.licensePlate.LANDING_INVALID(vinTabLink));
        updatedErrorMap.delete('licensePlate');
      }

      this.fireLicensePlateLookupTracking(TrackingConstant.VIN_NOT_FOUND);

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isSubmitting: false, errorMap: updatedErrorMap, isLpValid: false });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('pageshow', this.setDefaultButtonStateForCachedPage);
  }

  onLicensePlateChange = e => {
    const { storage } = this.props;

    const value = e.target.value;
    this.setState({ licensePlateInputValue: value }, () => {
      this.clearFormState('licensePlate');
      this.props.onValueChange();

      if (storage) {
        storage.set(LP_AND_VIN_STEP_STORAGE_KEYS.LICENSE_PLATE, value);
      }
    });
  };

  onStateCodeChange = option => {
    const { storage } = this.props;

    const value = option.stateCode;
    this.setState({ stateCodeInputValue: value }, () => {
      this.clearFormState('stateCode');
      this.props.onValueChange();

      if (storage) {
        storage.set(LP_AND_VIN_STEP_STORAGE_KEYS.STATE_CODE, value);
      }
    });
  };

  onSubmit = e => {
    const { setVin, clearVin, clearSquishVinEligibility } = this.props;

    e.preventDefault();

    delayHeavyTask(() => {
      this.setState({ isSubmitting: true }, () => {
        setVin('');
        clearVin(); // clear VIN from parent
        clearSquishVinEligibility(); // clear squishVinEligibility to prepare the next step
        this.validateAndSubmit();
      });
    });
  };

  setDefaultButtonStateForCachedPage = ({ persisted }) => {
    if (persisted) {
      this.resetIsSubmitting();
      this.setState({ wasFormSubmitted: false });
    }
  };

  setSquishVinError = () => {
    const { errorMap } = this.state;

    const updatedErrorMap = new Map(errorMap);

    updatedErrorMap.set('licensePlateWide', ERROR_MESSAGE_MAP.licensePlate.NO_STYLES);
    updatedErrorMap.delete('licensePlate');

    this.setState({
      errorMap: updatedErrorMap,
      isSubmitting: false,
      isLpValid: false,
    });
  };

  setStylesIds = async vinValue => {
    const { setStylesAndVin, licensePlate, setStyleIds: setContextStyleIds } = this.props;
    const { results: styles = [], hasError } = await getVinStylesDataWithMetadata(vinValue);

    if (hasError) {
      this.setSquishVinError();
      return;
    }

    setStylesAndVin(styles, vinValue, licensePlate);
    const styleIds = styles.map(({ styleId }) => styleId);

    setContextStyleIds(styleIds);
  };

  setIsMultipleVinProcessed = value => {
    this.setState({ isMultipleVinProcessed: value });
  };

  resetIsSubmitting = () => {
    this.setState({ isSubmitting: false });
  };

  clearFormState = errorMapKey => {
    const { errorMap } = this.state;

    const updatedErrorMap = new Map(errorMap);

    updatedErrorMap.delete(errorMapKey);

    this.setState({
      isSubmitting: false,
      wasFormSubmitted: false,
      errorMap: updatedErrorMap,
    });
  };

  validateAndSubmit = () => {
    const { creativeId, ctaText, setLicensePlate, setStateCode } = this.props;
    const { licensePlateInputValue, stateCodeInputValue, errorMap } = this.state;

    const isValidLicensePlate = validation.validateLicensePlate(licensePlateInputValue);
    const isValidStateCode = validation.validateValue(stateCodeInputValue);
    const isLpInputValid = isValidStateCode && isValidLicensePlate;

    const updatedErrorMap = new Map(errorMap);

    if (isValidLicensePlate) {
      updatedErrorMap.delete('licensePlate');
      updatedErrorMap.delete('licensePlateWide');
    } else {
      updatedErrorMap.set('licensePlate', ERROR_MESSAGE_MAP.licensePlate.INVALID);
      updatedErrorMap.delete('licensePlateWide');
    }
    if (isValidStateCode) {
      updatedErrorMap.delete('stateCode');
    } else {
      updatedErrorMap.set('stateCode', ERROR_MESSAGE_MAP.stateCode.INVALID);
    }

    this.setState(
      {
        errorMap: updatedErrorMap,
        isLpValid: isLpInputValid,
        isSubmitting: isLpInputValid,
        wasFormSubmitted: true,
      },
      () => {
        // Set LP and State code to get VIN data
        if (isLpInputValid) {
          setLicensePlate(licensePlateInputValue);
          setStateCode(stateCodeInputValue);

          const fireTracking = (eventType, subactionName, value) => {
            EventToolbox.fireTrackAction({
              event_type: eventType,
              event_data: {
                ...commonEventData,
                subaction_name: subactionName,
                creative_id: creativeId,
                value,
              },
            });
          };

          fireTracking(TrackingConstant.EVENT_TYPE_ACTION_PROGRESS, TrackingConstant.SUBMIT_LICENSE_PLATE, ctaText);
          fireTracking(
            TrackingConstant.EVENT_TYPE_ACTION_START,
            TrackingConstant.LICENSE_PLATE,
            redactLicensePlateString(licensePlateInputValue)
          );
          fireTracking(
            TrackingConstant.EVENT_TYPE_ACTION_START,
            TrackingConstant.LICENSE_PLATE_STATE,
            stateCodeInputValue
          );
        }
      }
    );
  };

  fireLicensePlateLookupTracking = value => {
    const { creativeId } = this.props;
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
      event_data: {
        ...commonEventData,
        subaction_name: TrackingConstant.LICENSE_PLATE_LOOKUP,
        creative_id: creativeId,
        value,
      },
    });
  };

  render() {
    const {
      vinFromLicensePlate,
      creativeId,
      ctaText,
      hideVinLicensePlateBtn,
      tabContentModal,
      addressToVinDrawer,
      squishVinError,
      squishStyles,
      isEval4351Chal,
      isEmoSearchEnabled,
      vehicle,
      isAppraisalValuePage,
      onMultipleVinsModalOpen,
      onMultipleVinsModalClose,
      isProjectEveStyle,
      isLicensePlateAndVinStep,
      isVinByAddressOverlay,
      isEscapeHatch,
    } = this.props;
    const {
      licensePlateInputValue,
      stateCodeInputValue,
      isLpValid,
      errorMap,
      isSubmitting,
      wasFormSubmitted,
      isMultipleVinProcessed,
    } = this.state;

    const componentStyleProperties = getLicensePlateTabStyleProperties(this.props);
    const hasError = !isEmpty(errorMap);

    return (
      <LicensePlateTab
        vehicle={vehicle}
        componentStyleProperties={componentStyleProperties}
        isSubmitting={isSubmitting}
        wasFormSubmitted={wasFormSubmitted}
        isLpValid={isLpValid}
        errorMap={errorMap}
        creativeId={creativeId}
        ctaText={ctaText}
        setSquishVinError={this.setSquishVinError}
        vinFromLicensePlate={vinFromLicensePlate}
        hideVinLicensePlateBtn={hideVinLicensePlateBtn}
        tabContentModal={tabContentModal}
        addressToVinDrawer={addressToVinDrawer}
        squishVinError={squishVinError}
        squishStyles={squishStyles}
        isEmoSearchEnabled={isEmoSearchEnabled}
        isAppraisalValuePage={isAppraisalValuePage}
        setIsMultipleVinProcessed={this.setIsMultipleVinProcessed}
        onMultipleVinsModalOpen={onMultipleVinsModalOpen}
        onMultipleVinsModalClose={onMultipleVinsModalClose}
        isMultipleVinProcessed={isMultipleVinProcessed}
        isLicensePlateAndVinStep={isLicensePlateAndVinStep}
        lpInputSlot={
          <LicensePlateTabLpInput
            licensePlateInputValue={licensePlateInputValue}
            onLicensePlateChange={this.onLicensePlateChange}
            errorMap={errorMap}
            isEval4351Chal={isEval4351Chal}
            {...componentStyleProperties}
          />
        }
        stateCodeInputSlot={
          <LicensePlateTabStateCodeInput
            stateCodeInputValue={stateCodeInputValue}
            onStateCodeChange={this.onStateCodeChange}
            errorMap={errorMap}
            {...componentStyleProperties}
          />
        }
        submitButtonSlot={
          <AppraisalTabsSubmitButton
            containerClassName={classnames({
              'mt-0_5': componentStyleProperties.isLookUpStyleView,
              'mt-lg-1_75': componentStyleProperties.isBackgroundStyleTabEnabled || isProjectEveStyle,
            })}
            buttonClassName="btn-responsive-sm-down"
            onSubmit={this.onSubmit}
            ctaText={ctaText}
            isSubmitting={isSubmitting}
            isDisabled={isSubmitting || (wasFormSubmitted && !isLpValid)}
            data-test="submit-license-plate-btn"
          />
        }
        overlaySubmitButtonSlot={
          <AppraisalTabsSubmitButton
            block
            buttonClassName={classnames('heading-5 text-white epo-license-plate-continue-btn btn-responsive-sm-down', {
              'py-1': isEscapeHatch && !isVinByAddressOverlay,
              'py-0_5': !isEscapeHatch && !isVinByAddressOverlay,
            })}
            onSubmit={this.onSubmit}
            ctaText={ctaText}
            isSubmitting={isSubmitting || (wasFormSubmitted && isVinByAddressOverlay && !hasError)}
            isDisabled={isSubmitting || (wasFormSubmitted && (!isLpValid || (isVinByAddressOverlay && !hasError)))}
            data-tracking-id="epo_submit_license_plate"
            data-test="submit-license-plate-btn"
            data-tracking-value={componentStyleProperties.isSecondary ? `${ctaText} 2` : ctaText}
            aria-label={ctaText}
          />
        }
      />
    );
  }
}

LicensePlateTabContainerUI.propTypes = {
  licensePlate: PropTypes.string,
  stateCode: PropTypes.string,
  setVin: PropTypes.func.isRequired,
  setLicensePlate: PropTypes.func.isRequired,
  setStateCode: PropTypes.func.isRequired,
  setStyleIds: PropTypes.func.isRequired,
  clearVin: PropTypes.func,
  onCompleteCallback: PropTypes.func,
  onValueChange: PropTypes.func,
  vehicle: PropTypes.shape({
    make: PropTypes.shape({
      slug: PropTypes.string,
    }),
    model: PropTypes.shape({
      slug: PropTypes.string,
    }),
    year: PropTypes.shape({
      year: PropTypes.string,
    }),
  }),
  vinEligibility: PropTypes.shape({}),
  vinFromLicensePlate: PropTypes.shape({
    vin: PropTypes.string,
    service: PropTypes.string,
    vins: PropTypes.arrayOf(PropTypes.string),
  }),
  vin: PropTypes.string,
  creativeId: PropTypes.string,
  ctaText: PropTypes.string,
  licensePlateLookupError: PropTypes.bool,
  vinTabLink: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  setStylesAndVin: PropTypes.func,
  setModelValue: PropTypes.func.isRequired,
  clearSquishVinEligibility: PropTypes.func,
  squishStyles: VehicleVinEntities.SquishStyles,
  hideVinLicensePlateBtn: PropTypes.bool,
  tabContentModal: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  addressToVinDrawer: PropTypes.node,
  squishVinError: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
  isVinByAddressOverlay: PropTypes.bool,
  isBackgroundStyleTabEnabled: PropTypes.bool,
  isVdpEmbedded: PropTypes.bool,
  isAppraisalValuePage: PropTypes.bool,
  isEscapeHatchDrawer: PropTypes.bool,
  isLocationStyleStep: PropTypes.bool,
  isEscapeHatch: PropTypes.bool,
  isLookUpStyleView: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isLicensePlateAndVinStep: PropTypes.bool,
  isMobile: PropTypes.bool,
  isEval4351Chal: PropTypes.bool,
  isEmoSearchEnabled: PropTypes.bool,
  onMultipleVinsModalOpen: PropTypes.func,
  onMultipleVinsModalClose: PropTypes.func,
  isAppraiserPageOpened: PropTypes.bool,
  isSellInCityState: PropTypes.bool,
  isWIDG1125chal1: PropTypes.bool,
  storage: PropTypes.shape({}),
  isProjectEveStyle: PropTypes.bool,
  isAddressTab: PropTypes.bool,
};

LicensePlateTabContainerUI.defaultProps = {
  licensePlate: '',
  stateCode: '',
  vehicle: null,
  vinFromLicensePlate: null,
  vinEligibility: null,
  vin: '',
  creativeId: APPRAISAL_VEHICLE_ENTRY_CREATIVE_ID,
  setStylesAndVin: noop,
  clearVin: noop,
  onCompleteCallback: noop,
  onValueChange: noop,
  clearSquishVinEligibility: noop,
  ctaText: '',
  licensePlateLookupError: false,
  vinTabLink: null,
  squishStyles: null,
  hideVinLicensePlateBtn: false,
  tabContentModal: null,
  addressToVinDrawer: null,
  squishVinError: '',
  isVinByAddressOverlay: false,
  isBackgroundStyleTabEnabled: false,
  isVdpEmbedded: false,
  isAppraisalValuePage: false,
  isEscapeHatchDrawer: false,
  isLocationStyleStep: false,
  isEscapeHatch: false,
  isLookUpStyleView: false,
  isFullWidth: false,
  isSecondary: false,
  isLicensePlateAndVinStep: false,
  isMobile: false,
  isEval4351Chal: false,
  isEmoSearchEnabled: false,
  onMultipleVinsModalOpen: noop,
  onMultipleVinsModalClose: noop,
  isAppraiserPageOpened: false,
  isSellInCityState: false,
  isWIDG1125chal1: false,
  storage: null,
  isProjectEveStyle: false,
  isAddressTab: false,
};

export const stateToPropsConfig = {
  squishStyles: bindToPath(
    ({ vin }) => buildStylesBasicPathFromSquishVin({ squishVin: getSquishVIN(vin) }),
    VehicleVinModel
  ),
  vinFromLicensePlate: bindToPath(
    ({ licensePlate, stateCode }) => buildLicensePlateStateCodeForVinPath({ licensePlate, stateCode }),
    PartnerOfferModel
  ),
};

export const mapStateToProps = state => ({
  licensePlateLookupError: getLicensePlateErrorState(state),
  isAppExtensionPage: state.pageContext?.isAppExtensionPage,
});

const LicensePlateTabWrapper = connect(mapStateToProps)(connectToModel(LicensePlateTabContainerUI, stateToPropsConfig));

export function LicensePlateTabContainer(props) {
  const {
    vin,
    licensePlate,
    stateCode,
    setVin,
    setLicensePlate,
    setStateCode,
    setStyleIds,
    onMultipleVinsModalOpen,
    onMultipleVinsModalClose,
    isAppraiserPageOpened,
    isProjectEveStyle,
  } = useContext(AppraisalTabsContext);

  return (
    <LicensePlateTabWrapper
      {...props}
      vin={vin}
      licensePlate={licensePlate}
      stateCode={stateCode}
      setVin={setVin}
      setLicensePlate={setLicensePlate}
      setStateCode={setStateCode}
      setStyleIds={setStyleIds}
      onMultipleVinsModalOpen={onMultipleVinsModalOpen}
      onMultipleVinsModalClose={onMultipleVinsModalClose}
      isAppraiserPageOpened={isAppraiserPageOpened}
      isProjectEveStyle={isProjectEveStyle}
    />
  );
}
