import PropTypes from 'prop-types';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { EdmundsAPI } from 'client/data/api/api-client';
import { withMetrics } from 'client/data/api/api-metrics';

const State = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  niceName: PropTypes.string,
  type: PropTypes.string,
  niceType: PropTypes.string,
  stateCode: PropTypes.string,
  population: PropTypes.number,
});

export const StateEntities = {
  State,
  States: PropTypes.arrayOf(State),
};

export const StatesModel = createModelSegment('states', [
  {
    path: 'allStates',
    resolve: (match, context) => withMetrics(EdmundsAPI, context).fetchJson('/state/v2/'),
  },
  {
    path: 'allStateCodes',
    resolve: (match, context) =>
      withMetrics(EdmundsAPI, context).fetchJson('/state/v2/?view=custom,fieldsInclude:stateCode&sortby=stateCode:ASC'),
  },
]);
