import React from 'react';
import PropTypes from 'prop-types';
import { lazyLoad } from 'site-modules/shared/components/lazy-load/lazy-load';
import { AppraisalLazyLoadDrawerLoadingPlaceholder } from 'site-modules/shared/components/appraisal/appraisal-lazy-load-drawer-loading-placeholder/appraisal-lazy-load-drawer-loading-placeholder';

const LoadingComponent = ({ lazyLoadingPlaceholderBackButtonSlot }) => (
  <AppraisalLazyLoadDrawerLoadingPlaceholder backButtonSlot={lazyLoadingPlaceholderBackButtonSlot} />
);

LoadingComponent.propTypes = {
  lazyLoadingPlaceholderBackButtonSlot: PropTypes.node.isRequired,
};

export const AppraisalFindVinDrawerContentAsync = lazyLoad({
  loader: () =>
    import(/* webpackChunkName: "appraisal-find-vin-drawer-content" */ './appraisal-find-vin-drawer-content'),
  componentName: 'AppraisalFindVinDrawerContent',
  loading: LoadingComponent,
});
