export const DRAWER_DETAIL_BUTTON_CTA_TEXT = {
  OFFER: 'Get My Offer',
  APPRAISE: 'No thanks. I just want an appraisal',
  APPRAISE_ADDRESS_DRAWER: 'Get appraisal report without a VIN',
};

export const DRAWER_TRIGGER_CTA_TEXT = {
  licensePlate: "Don't have your license plate?",
  vin: "Don't have your VIN?",
  address: 'Find my VIN',
};
