import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'reactstrap/lib/Modal';
import { noop } from 'lodash';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { MultipleVinsModalContentAsync } from './multiple-vins-modal-content-async';

import './multiple-vins-modal.scss';

const CREATIVE_ID = 'license-plate-lookup-multi-vin-popup';

export class MultipleVinsModal extends Component {
  onOpened = () => {
    this.props.onOpen();
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_WIDGET_VIEW,
      event_data: {
        creative_id: CREATIVE_ID,
      },
    });
  };

  onClosed = () => {
    this.props.onClose();
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_name: TrackingConstant.ACTION_SHOW_CONTENT,
        subaction_name: TrackingConstant.ACTION_CLOSE,
        creative_id: CREATIVE_ID,
      },
    });
  };

  render() {
    const { isOpen, toggle } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        onOpened={this.onOpened}
        onClosed={this.onClosed}
        wrapClassName="multiple-vins-modal"
        contentClassName="multiple-vins-modal-content"
        zIndex={1056} // $zindex-modal-drawer + 1
      >
        {isOpen && <MultipleVinsModalContentAsync creativeId={CREATIVE_ID} {...this.props} />}
      </Modal>
    );
  }
}

MultipleVinsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  vins: PropTypes.arrayOf(PropTypes.string),
  onSelectVin: PropTypes.func,
  vehicle: PropTypes.shape({
    make: PropTypes.shape({
      slug: PropTypes.string,
    }),
    model: PropTypes.shape({
      slug: PropTypes.string,
    }),
    year: PropTypes.shape({
      year: PropTypes.string,
    }),
  }),
  isAppraisalValuePage: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

MultipleVinsModal.defaultProps = {
  vins: [],
  ctaBtnColor: 'success',
  onSelectVin: noop,
  vehicle: null,
  isAppraisalValuePage: false,
  onOpen: noop,
  onClose: noop,
};
